<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('subscription_title') }}</dmx-title>

        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ subscriptionList.length }} {{ $t('subscription_subscriptions_found') }}
            <!--     <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)"
                        :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)"
                        :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(1000)"
                        :style="((options.perPage == 1000) ? 'font-weight: bold;' : ' ')">All</b-link>
                </span> -->
            </b-col>
          <!--   <b-col>
                <b-input v-model="searchField" @input="searchWithText()" :loading="searchFieldIsLoading"
                    :placeholder="$t('common_search')">
                </b-input>
            </b-col> -->
        </b-row>

        <!-- Need to have chunks the list (GroupBy equivelent ?) and separate trough Gates -->

        <b-table id="devices-table" style="cursor: default !important;" thead-class="dmx-thead-block" tbody-tr-class="dmx-pointer-element" :busy="loading"
            outlined striped bordered hover :items="subscriptionList" :fields="headers">
        </b-table>
 <!--        <div>
            <b-pagination align="center" v-model="options.currentPage" :total-rows="pagination.totalListCount"
                :per-page="options.perPage" aria-controls="sub-table">
            </b-pagination>
        </div> -->
    </div>
</template>

<style scoped></style>

<script>
import subscriptionService from '@/services/subscriptionService';
import router from '@/router';
export default {
    name: "SubscriptionList",
    data: () => ({
        search: '',
        loading: true,
        options: {
            perPage: 10,
            currentPage: 1,
        },

        //flags
        searchFieldIsLoading: false,

        // values
        searchField: '',
        subscriptionList: [],

        // pagination
        pagination: {
            totalListCount: 0
        },
        filterTableHeadName: '',
        timeoutId: 0,
        bcItems: [
            {
                text: 'subscriptions',
                href: '/SubscriptionList',
                active: true,
            }
        ]
    }),
    computed: {
        headers() {
            return [
                {
                    label: this.$t('common_id'),
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                {
                    label: 'GateName',
                    align: 'start',
                    sortable: true,
                    key: 'gateName',
                },
                {
                    label: 'Name',
                    align: 'start',
                    sortable: true,
                    key: 'subscription.name',
                },
                {
                    label: 'Price',
                    align: 'start',
                    sortable: true,
                    key: 'subscription.price',
                },
                {
                    label: 'Currency',
                    align: 'start',
                    sortable: true,
                    key: 'subscription.currency.currencyCode',
                },
            ]
        }
    },
    methods: {
        setPerPage(num) {
            this.options.currentPage = 1;
            this.options.perPage = num;
        },
        searchWithText() {
            this.searchFieldIsLoading = true;

            if (this.timeoutId > 0) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = window.setTimeout(() => {
                this.getSubscriptionList();
            }, 600);
        },

        filterByTableHeader(tableHeaderName) {
            this.filterTableHeadName = tableHeaderName;
            this.getSubscriptionList();
        },
        getGateSubscriptionList() {

            subscriptionService.getGateSubscriptions("GetAllGateSubscriptions", {
                Count: this.options.perPage,
                SubscriptionTemplate: [],
                SearchTerm: this.searchField,
                Page: this.options.currentPage
            })
                .then((data) => {
                    this.subscriptionList = data;
                    this.searchFieldIsLoading = false;
                    this.options.currentPage = data.page;
                    this.pagination.totalListCount = data.totalListCount;
                    this.loading = false
                })
                .catch(function (error) {
                    alert(error);
                });
        },
    },
    mounted() {
        this.getGateSubscriptionList();
    }
}
</script>
